import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const PageNavigation = ({ title }) => {
  return (
    <Wrapper>
      <NavLink to="/">Home</NavLink>/ {title}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  height: 4rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 2rem;
  padding-right: 2.5rem;

  a {
    font-size: 2rem;
  }
`;

export default PageNavigation;
