import HeroSection from "./components/HeroSection";
import { useProductContext } from "./context/productcontex";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const About = () => {
  const { myName } = useProductContext();

  const data = {
    name: "DVN Ecommerce",
  };

  return (
    <Wrapper>
      <p className="bredcrum-line"><NavLink to="/" className="contact-navigation">Home </NavLink>/ About us</p>
      {myName}
      <HeroSection myData={data} />
    </Wrapper>
  );
};


  const Wrapper = styled.section`
  .contact-navigation{
    justify-content: start;
    align-items: start;
    font-size: 2rem;
    padding-left: 5.2rem;
    color:#6254f3;
    
  }
  .bredcrum-line {
    height: 1rem;
    font-size: 2rem;
    text-align:right;
    padding: 0 1.8rem;
  }
`;

export default About;
