import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faHome, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { Nav, NavItem} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import styled from "styled-components";

const tabs = [{
  route: "/",
  icon: faHome,
  label: "Home"
},{
  route: "/products",
  icon: faList,
  label: "Product"
},{
  route: "/login",
  icon: faUserCircle,
  label: "Login"
}]

const NavigationBottom = (props) => {
    return (
        <Wrapper>       
          {/* Bottom Tab Navigator*/}
          <nav className="navbar fixed-bottom navbar-light d-block d-lg-none bottom-tab-nav" role="navigation">
            <Nav className="w-100">
              <div className=" d-flex flex-row justify-content-around w-100">
                {
                  tabs.map((tab, index) =>(
                    <NavItem key={`tab-${index}`}>
                      <NavLink to={tab.route} className="nav-link active">
                        <div className="row d-flex flex-column justify-content-center">
                          <FontAwesomeIcon size="lg" icon={tab.icon}/>
                          <div>{tab.label}</div>
                        </div>
                      </NavLink>
                    </NavItem>
                  ))
                }
              </div>
            </Nav>
          </nav>
        </Wrapper>
      )
    };
    export default NavigationBottom;


const Wrapper = styled.section`
    .navbar{
      background: #fff;
      height: 45px;
      font-size: 10px;
      .nav-link{
      color: #6254f3;
      }
    }
    .fa-house, .fa-list, .fa-circle-user{
      font-size: 15px;
      color: #6254f3;
      box-sizing: inherit!important;
    }
`;