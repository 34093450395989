import styled from "styled-components";
import FilterSection from "./components/FilterSection";
import ProductList from "./components/ProductList";
import Sort from "./components/Sort";
import { NavLink } from "react-router-dom";


const Products = () => {
  return (
    <Wrapper>
      <p className="bredcrum-line"><NavLink to="/" className="contact-navigation">Home </NavLink>/ Products</p>
      <div className="container grid grid-filter-column">
        <div>
          <FilterSection />
        </div>

        <section className="product-view--sort">
          <div className="sort-filter">
            <Sort />
          </div>
          <div className="main-product">
            <ProductList />
          </div>
        </section>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .grid-filter-column {
    grid-template-columns: 0.2fr 1fr;
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .grid-filter-column {
      grid-template-columns: 1fr;
    }
  }

  .contact-navigation{
    justify-content: start;
    align-items: start;
    font-size: 2rem;
    padding-left: 5.2rem;
    color:#6254f3;
    
  }
  .bredcrum-line {
    font-size: 2rem;
    text-align:right;
    padding: 0 1.8rem;
  }
`;

export default Products;
